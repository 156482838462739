import { FC } from 'react'

import { cva } from 'class-variance-authority'

import { twMerge } from '~/lib/utils'

type SearchResultCountProps = {
  count: number
  preCountText?: string
  layout?: 'row' | 'column'
}

const searchResultCountContainerStyles = cva('flex w-fit shrink-0', {
  variants: {
    layout: {
      row: 'flex-row gap-1 items-center',
      column: 'flex-col items-center',
    },
  },
})

const countTexContainerStyles = cva('flex gap-1', {
  variants: {
    layout: {
      row: 'items-center',
      column: 'items-end',
    },
  },
})

export const SearchResultCount: FC<SearchResultCountProps> = ({
  count,
  preCountText = '',
  layout = 'row',
}) => {
  return (
    <div
      className={twMerge(
        'text-body-s',
        searchResultCountContainerStyles({ layout }),
      )}
    >
      {preCountText}
      <div className={countTexContainerStyles({ layout })}>
        <span className="text-2xl font-bold text-brand">
          {count.toLocaleString()}
        </span>
        件
      </div>
    </div>
  )
}
