// import { type DialogProps } from "@radix-ui/react-dialog"
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'

import { Command as CommandPrimitive } from 'cmdk'

import { cn } from '~/lib/utils'

const Command = forwardRef<
  ElementRef<typeof CommandPrimitive>,
  ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({ className, ...props }, ref) => (
  <CommandPrimitive
    className={cn(
      'flex h-full w-full flex-col overflow-hidden rounded',
      className,
    )}
    ref={ref}
    {...props}
  />
))
Command.displayName = CommandPrimitive.displayName

// 現状つかっていないのでコメントアウト
// interface CommandDialogProps extends DialogProps {}
//
// const CommandDialog = ({ children, ...props }: CommandDialogProps) => {
//   return (
//     <Dialog {...props}>
//       <DialogContent className="overflow-hidden p-0 shadow-lg">
//         <Command className="[&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-muted-foreground [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:h-5 [&_[cmdk-input-wrapper]_svg]:w-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:h-5 [&_[cmdk-item]_svg]:w-5">
//           {children}
//         </Command>
//       </DialogContent>
//     </Dialog>
//   )
// }

// 現状つかっていないのでコメントアウト
// const CommandInput = forwardRef<
//   ElementRef<typeof CommandPrimitive.Input>,
//   ComponentPropsWithoutRef<typeof CommandPrimitive.Input>
// >(({ className, ...props }, ref) => (
//   <div className="flex items-center border-b px-3" cmdk-input-wrapper="">
//     <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
//     <CommandPrimitive.Input
//       className={cn(
//         'placeholder:text-muted-foreground flex h-11 w-full rounded-md bg-transparent py-3 text-sm outline-none disabled:cursor-not-allowed disabled:opacity-50',
//         className,
//       )}
//       ref={ref}
//       {...props}
//     />
//   </div>
// ))
//
// CommandInput.displayName = CommandPrimitive.Input.displayName

const CommandList = forwardRef<
  ElementRef<typeof CommandPrimitive.List>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.List
    className={cn('max-h-[300px] overflow-y-auto overflow-x-hidden', className)}
    ref={ref}
    {...props}
  />
))

CommandList.displayName = CommandPrimitive.List.displayName

// 現状つかっていないのでコメントアウト
// const CommandEmpty = forwardRef<
//   ElementRef<typeof CommandPrimitive.Empty>,
//   ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
// >((props, ref) => (
//   <CommandPrimitive.Empty
//     className="py-6 text-center text-sm"
//     ref={ref}
//     {...props}
//   />
// ))
//
// CommandEmpty.displayName = CommandPrimitive.Empty.displayName

const CommandGroup = forwardRef<
  ElementRef<typeof CommandPrimitive.Group>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Group
    className={cn(
      'overflow-hidden p-1 [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-group-heading]]:font-medium',
      className,
    )}
    ref={ref}
    {...props}
  />
))

CommandGroup.displayName = CommandPrimitive.Group.displayName

// 現状つかっていないのでコメントアウト
// const CommandSeparator = forwardRef<
//   ElementRef<typeof CommandPrimitive.Separator>,
//   ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
// >(({ className, ...props }, ref) => (
//   <CommandPrimitive.Separator
//     className={cn('bg-border -mx-1 h-px', className)}
//     ref={ref}
//     {...props}
//   />
// ))
// CommandSeparator.displayName = CommandPrimitive.Separator.displayName

const CommandItem = forwardRef<
  ElementRef<typeof CommandPrimitive.Item>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Item
    className={cn(
      'relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none aria-selected:bg-grayBg data-[disabled=true]:pointer-events-none data-[disabled=true]:opacity-50',
      className,
    )}
    ref={ref}
    {...props}
  />
))

CommandItem.displayName = CommandPrimitive.Item.displayName

// 現状つかっていないのでコメントアウト
// const CommandShortcut = ({
//   className,
//   ...props
// }: HTMLAttributes<HTMLSpanElement>) => {
//   return (
//     <span
//       className={cn(
//         'text-muted-foreground ml-auto text-xs tracking-widest',
//         className,
//       )}
//       {...props}
//     />
//   )
// }
// CommandShortcut.displayName = 'CommandShortcut'

export { Command, CommandList, CommandGroup, CommandItem }
