import { FC } from 'react'

import { cva } from 'class-variance-authority'

import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~/components'
import { IconArrowDown } from '~/components/ui/icons/arrow-down'

import {
  SearchResultOrder,
  SearchResultOrderItem,
} from './use-select-search-result-order'

type SelectSearchResultOrderProps = {
  items: SearchResultOrderItem[]
  open: boolean
  placeholder: string
  size?: 'small' | 'medium'
  setOpen: (open: boolean) => void
  onSelect: (currentValue: SearchResultOrder) => void
}

const buttonVariants = cva(
  'flex items-center justify-between rounded border border-grayBorder px-2 py-2 text-body',
  {
    variants: {
      size: {
        small: 'h-[48px] w-[133px]',
        medium: 'h-[52px] w-[163px]',
      },
    },
    defaultVariants: {
      size: 'small',
    },
  },
)

const popOverVariants = cva('p-0', {
  variants: {
    size: {
      small: 'w-[133px]',
      medium: 'w-[163px]',
    },
  },
  defaultVariants: {
    size: 'small',
  },
})

export const SelectSearchResultOrder: FC<SelectSearchResultOrderProps> = ({
  items,
  open,
  placeholder,
  setOpen,
  size = 'medium',
  onSelect,
}) => {
  return (
    <Popover onOpenChange={setOpen} open={open}>
      <PopoverTrigger asChild>
        <button aria-expanded={open} className={buttonVariants({ size })}>
          {placeholder}
          <IconArrowDown />
        </button>
      </PopoverTrigger>
      <PopoverContent className={popOverVariants({ size })}>
        <Command className="w-full">
          <CommandList>
            <CommandGroup>
              {items.map((item) => (
                <CommandItem
                  className="h-11 px-4 md:h-8"
                  key={item.value}
                  onSelect={() => onSelect(item.value)}
                  value={item.value}
                >
                  {item.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
