import { FC, HTMLAttributes } from 'react'

import { cva } from 'class-variance-authority'

import { IconSearch } from '~/components/ui/icons/search'
import { twMerge } from '~/lib/utils'

type ButtonProps = HTMLAttributes<HTMLButtonElement> & {
  size?: 'small' | 'medium' | 'large'
  onClick: () => void
}

const buttonVariants = cva(
  'flex w-full items-center justify-center gap-2 rounded border border-grayBtn px-4 py-2 text-body-bold text-brand hover:bg-brand-bg bg-white',
  {
    variants: {
      size: {
        small: 'h-[48px]',
        medium: 'h-[52px]',
        large: 'h-[62px]',
      },
    },
    defaultVariants: {
      size: 'small',
    },
  },
)

export const OpenSearchForm: FC<ButtonProps> = ({
  children,
  size,
  className,
  onClick,
  ...props
}) => {
  return (
    <button
      className={twMerge(buttonVariants({ size }), className)}
      onClick={onClick}
      {...props}
    >
      <IconSearch color="brand" height={24} width={24} />
      {children}
    </button>
  )
}
